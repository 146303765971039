import React, {Component} from 'react';
import NavBar from './components/NavBar';
import HeroSliderOne from './components/HeroSliderOne';
import BrandLogoSlider from './components/BrandLogoSlider';
import ProjectSlider from './components/ProjectSlider';
import ServiceTab from './components/ServiceTab';
import Footer from './components/Footer';
import {animateScroll as scroll } from 'react-scroll';


class HomeOne extends Component {

    scrollToTop(){
        scroll.scrollToTop();
    }

    componentDidMount() {
        this.scrollToTop();
    }

    componentDidUpdate() {
        //this.scrollToTop();
    }

    render(){
        this.scrollToTop();

        return(
                     
            <div>

                {/* Navigation bar */}
                <NavBar/>
                
                {/* Hero slider */}
                <HeroSliderOne/>

                {/* Service Tab */}
                <ServiceTab/>

                {/* Project Slider */}
                <ProjectSlider/>

                {/* Brand logo */}
                <BrandLogoSlider background = "" />

                {/* Footer */}
                <Footer/>

            </div>
        )
    }
}


export default HomeOne;