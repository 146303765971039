import React, {Component} from 'react';
import {animateScroll as scroll } from 'react-scroll';
class Footer extends Component{

    
    constructor(props){
        super(props);
        this.state = {};
        this.handleScroll = this.handleScroll.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    handleScroll() {
        if(this.mount){
            this.setState({scroll: window.scrollY});
        }
    }

    scrollToTop (){
        scroll.scrollToTop();
    }
  
    componentDidMount() {
        this.mount = true;
        console.log("BOB THE BUILDER");
        const el = document.querySelector('nav');
        this.setState({top: el.offsetTop, height: el.offsetHeight});
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentDidUpdate() {
        this.state.scroll > this.state.top ? 
        document.getElementById("scroll-top").classList.add("show") :
        document.getElementById("scroll-top").classList.remove("show") ;
    }

    componentWillUnmount(){
        this.mount = false;
    }

    render(){
        return(
            <div>
                {/*====================  footer area ====================*/}
                <div className="footer-area dark-bg">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-content-wrapper section-space--inner--60">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-12">
                                {/* footer intro wrapper */}
                                <div className="footer-intro-wrapper">
                                    <div className="footer-logo">
                                    <a href={`${process.env.PUBLIC_URL}`}>
                                        <img src="assets/img/logo/logo-light.png" className="img-fluid" alt="" />
                                    </a> 
                                    </div>
                                    <div className="footer-desc">
                                    Somos uma empresa especializada em manuntenção e bobinagem de equipamentos electricos, tais como, motores, bombas de água, moto redutores, geradores, entre outros. Realizamos também serviços de termografia e manuntenção elétrica.
                                    </div>
                                </div>
                                </div>
                                
                                <div className="col-xl-5 offset-xl-1 col-lg-6 col-md-6">
                                {/* footer widget */}
                                <div className="footer-widget mb-0">
                                    <h4 className="footer-widget__title">ENTRE EM CONTACTO</h4>
                                    <div className="footer-widget__content">
                                    <p className="address">Estrada Vale de Mulatas, Lote 10, Arm. 4<br/>2910-737 Setúbal</p>
                                    <ul className="contact-details">
                                        <li><span>Tel:</span>+351 964 015 371 | 265 229 187</li>
                                        <li><span>Email:</span>geral@bobiset.pt</li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                        <div className="footer-copyright-wrapper">
                        <div className="footer-copyright text-center">
                        Copyright © 2020. Todos os direitos reservados
                        </div>
                    </div>
                </div>
                {/*====================  End of footer area  ====================*/}
                {/*====================  scroll top ====================*/}
                <button className="scroll-top" id="scroll-top" onClick={this.scrollToTop}>
                    <i className="ion-android-arrow-up" />
                </button>
                {/*====================  End of scroll top  ====================*/}
            </div>
        )
    }
}


export default Footer;