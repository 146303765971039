import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class MobileMenu extends Component{

    state = {
        active : false
    }

    toggleMobileMenu = () => {
        this.setState({
            active: !this.state.active
        })
    }

    componentDidMount(){
        var offCanvasNav = document.getElementById('offcanvas-navigation');
        var offCanvasNavSubMenu = offCanvasNav.querySelectorAll('.sub-menu');

        for (let i = 0; i<offCanvasNavSubMenu.length; i++){
           offCanvasNavSubMenu[i].insertAdjacentHTML("beforebegin", "<span class='menu-expand'><i></i></span>");
        }

        var menuExpand = offCanvasNav.querySelectorAll('.menu-expand');
        var numMenuExpand = menuExpand.length;

        function sideMenuExpand() {

            if(this.parentElement.classList.contains('active') === true) {
                this.parentElement.classList.remove('active');
            } else {
                for (let i = 0; i < numMenuExpand; i++) {
                    menuExpand[i].parentElement.classList.remove('active');
                }
                this.parentElement.classList.add('active');
            }
        }

        for (let i = 0; i < numMenuExpand; i++) {
            menuExpand[i].addEventListener("click", sideMenuExpand);
        }
    }


    render(){
        return(
            <div>
                {/*=======  offcanvas mobile menu  =======*/}
                <div className= {`offcanvas-mobile-menu ${this.state.active ? 'active': ''} `} id="mobile-menu-overlay">
                    <a href="#/" className="offcanvas-menu-close" id="mobile-menu-close-trigger" onClick={this.toggleMobileMenu}>
                        <i className="ion-android-close" />
                    </a>
                    <div className="offcanvas-wrapper">
                        <div className="offcanvas-inner-content">
                            {/*
                            <div className="offcanvas-mobile-search-area">
                                <form action="#">
                                <input type="search" placeholder="Search ..." />
                                <button type="submit"><i className="fa fa-search" /></button>
                                </form>
                            </div>
                            */}
                            <nav className="offcanvas-navigation" id="offcanvas-navigation">
                                <ul>
                                    <li className="menu-item-has-children">
                                        <Link to={`${process.env.PUBLIC_URL}/home-one`}>HOME</Link>
                                    </li>
                                    <li className="has-children has-children--multilevel-submenu">
                                        <Link to={`${process.env.PUBLIC_URL}/empresa#quem-somos`}>EMPRESA</Link>
                                    </li>
                                    <li className="menu-item-has-children">
                                    <a href={`${process.env.PUBLIC_URL}/servicos/bobinagem-manutencao#bobinagem`}>BOBINAGEM</a>
                                    <a href={`${process.env.PUBLIC_URL}/servicos/bobinagem-manutencao#manutencao`}>MANUTENÇÃO</a>
                                    <Link to={`${process.env.PUBLIC_URL}/servicos/termografia`}>TERMOGRAFIA</Link>
                                    </li>
                                    <li><Link to="/contacto">CONTACTOS</Link> </li>
                                </ul>
                            </nav>
                            <div className="offcanvas-widget-area">
                                <div className="off-canvas-contact-widget">
                                <div className="header-contact-info">
                                    <ul className="header-contact-info__list">
                                    <li><i className="ion-android-phone-portrait" /><a href="tel:+351964015371">+351 964 015 371</a></li>
                                    <li><i className="ion-android-phone-portrait" /><a href="tel:+351265229187">+351 265 229 187</a></li>
                                    <li><i className="ion-android-mail" /> <a href="mailto:geral@bobiset.pt">geral@bobiset.pt</a></li>
                                    </ul>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*=======  End of offcanvas mobile menu  =======*/}

            </div>
        )
    }
}

export default MobileMenu;