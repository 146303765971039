import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import ServiceGallery from './components/ServiceGallery';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import {animateScroll as scroll } from 'react-scroll';

class Termografia extends Component {

    scrollToTop(){
        scroll.scrollToTop();
    }

    componentDidMount() {
        this.scrollToTop();
    }

    render(){

        let galleryData_termografia = [
            {img: 'termografia/termografia_1.jpg'},
            {img: 'termografia/termografia_2.jpg'},
            {img: 'termografia/termografia_3.jpg'},
            {img: 'termografia/termografia_4.jpg'}
        ];

        return(
            <div>
                {/* Navigation bar */}
                <NavBar />

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Termografia e manunteção elétrica</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li>Serviços - Termografia</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--120">
                    {/*Service section start*/}

                    <div className="">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <p>Em ambiente industrial é cada vez mais importante assegurar o correto funcionamento dos equipamentos, pelo que é de elevada importância investir numa manutenção preventiva periódica.</p>
                                    <p>Como na indústria, o correto funcionamento dos processos não depende apenas dos motores elétricos, a Bobiset reforçou as suas áreas de trabalho, apostando na manutenção preventiva dos equipamentos elétricos e mecânicos, dando assim início ao serviço de <strong>Termografia</strong>.</p>
                                    <p>Assim sendo, a BobiSet está habilitada a realizar qualquer serviço termográfico, com o apoio de um Eng. Eletrotécnico com certificado em <strong>ITC NÍVEL I</strong> (Infrared Training Center), e com o auxílio de todo o equipamento necessário para o efeito – <strong>FLIR E75 Advanced Thermal Camera</strong>.</p>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="service-section section-space--inner--60">
                        <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-12 order-2 order-lg-1">
                                {/* service gallery */}
                                <ServiceGallery galleryData={galleryData_termografia} />
                            </div>
                            <div className="col-lg-8 col-12 order-1 order-lg-2">
                            <div className="service-details">
                                <div className="content section-space--top--30">
                                <div className="row">
                                    <div className="col-12">
                                        <p>Através deste serviço, a BobiSet garante a qualidade e rigor na revisão/inspeção de:</p>
                                        <ul className="ul-inside--styled">
                                            <li>Instalações elétricas</li>
                                            <li>Motores Elétricos</li>
                                            <li>Condutas</li>
                                            <li>Isolamentos térmicos e edifícios</li>
                                        </ul>
                                    </div>
                                    <div className="col-12 section-space--top--30">
                                        <p>Com o apoio da <strong>Termografia</strong>, é possível detetar entre outros:</p>
                                        <ul className="ul-inside--styled">
                                            <li>Sobreaquecimento em instalações elétricas </li>
                                            <li>Anomalias elétricas</li>
                                            <li>Defeitos mecânicos</li>
                                            <li>Fugas e sedimentos em condutas</li>
                                            <li>Infiltrações e humidades em edifícios</li>
                                        </ul>
                                    </div>
                                    <div className="section-space--top--30 col-12">
                                        <p>Após a análise termográfica, e perante a existência de pontos quentes, a BobiSet disponibiliza-se também para prestar serviços de <strong>manutenção elétrica</strong> aos mesmos.</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>

                        </div>
                    </div>
                    {/*Service section end*/}
                </div>


                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default Termografia;