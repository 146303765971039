import React, {Component} from 'react';
import emailjs from 'emailjs-com';

class ContactUs extends Component {

    constructor(props) {
        super(props);
        this.state = {sent: false, success: false };
    }

    sendEmail = (e) => {
    var self = this; 
      e.preventDefault();
  
      emailjs.sendForm('gmail', 'template_0g1BkNn7', e.target, 'user_BwliyaqymiLbEXrUqwjNS')
        .then((result) => {
            console.log(result.text);
            self.setState({
                sent: true,
                success: true
              });
        }, (error) => {
            console.log(error.text);
        });
    }


  
    render(){
        let button;
        return(
            <form id="contact-form" onSubmit={this.sendEmail}>
            <div className="row row-10">
                <div className="col-md-6 col-12 section-space--bottom--20"><input name="con_name" type="text" placeholder="O seu nome" /></div>
                <div className="col-md-6 col-12 section-space--bottom--20"><input name="con_email" type="email" placeholder="O seu email" /></div>
                <div className="col-12"><textarea name="con_message" defaultValue={""} placeholder="A sua mensagem" /></div>
                
                {this.state.sent
                    ? (<p>Obrigado pelo seu contacto. A sua mensagem foi enviada.</p>)
                    : (<div className="col-12"><button type="submit">Enviar mensagem</button></div>)
                }
                
            </div>
            </form>
            );
    }
}

export default ContactUs;