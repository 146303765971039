import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import HomeOne from './HomeOne';
import About from './pages/About';
import Services from './service/Services';
import Termografia from './service/Termografia';
import Bobinagem from './service/Serv_Bobinagem';
import Contact from './pages/Contact';
import PageNotFound from './pages/404';
import NoMAtch from './pages/404';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomeOne}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/empresa`} component={About}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/servicos`} component={Services}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/servicos/bobinagem-manutencao`} component={Bobinagem}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/servicos/bobinagem-manutencao`} component={Bobinagem}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/servicos/termografia`} component={Termografia}/>
                    {/*
                    <Route exact path={`${process.env.PUBLIC_URL}/projects`} component={Projects}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/project-details`} component={ProjectDetails}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog-left-sidebar`} component={BlogLeftSidebar}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog-right-sidebar`} component={BlogRightSidebar}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog-details-left-sidebar`} component={BlogDetailsLeftSidebar}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog-details-right-sidebar`} component={BlogDetailsRightSidebar}/>
                    */}
                    <Route exact path={`${process.env.PUBLIC_URL}/contacto`} component={Contact}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/404`} component={PageNotFound}/>
                    <Route component={NoMAtch} />
                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));

serviceWorker.register();