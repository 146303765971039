import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import ServiceGallery from './components/ServiceGallery';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import {animateScroll as scroll } from 'react-scroll';


class Bobinagem extends Component {

    scrollToTop(){
        scroll.scrollToTop();
    }

    componentDidMount() {
        //this.scrollToTop();
    }

    render(){

        let galleryData_bobinagem = [
            {img: 'bobinagem/bobinagem_1.jpg'},
            {img: 'bobinagem/bobinagem_3.jpg'},
            {img: 'bobinagem/bobinagem_4.jpg'},
            {img: 'bobinagem/bobinagem_5.jpg'},
            {img: 'bobinagem/bobinagem_6.jpg'},
            {img: 'bobinagem/bobinagem_8.jpg'},
            {img: 'bobinagem/bobinagem_9.jpg'},
            {img: 'bobinagem/bobinagem_10.jpg'},
            {img: 'bobinagem/bobinagem_11.jpg'},
            {img: 'bobinagem/bobinagem_12.jpg'},
            {img: 'bobinagem/bobinagem_13.jpg'},
            {img: 'bobinagem/bobinagem_14.jpg'}
        ];

        let galleryData_manutencao = [
            {img: 'manutencao/manutencao_5.jpg'},
            {img: 'manutencao/manutencao_6.jpg'},
            {img: 'manutencao/manutencao_7.jpg'},
            {img: 'manutencao/manutencao_8.jpg'},
            {img: 'manutencao/manutencao_4.jpg'}

        ];

        return(
            <div>
                {/* Navigation bar */}
                <NavBar/>

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div id="bobinagem" className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Bobinagem e Manunteção</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li>Serviços - Bobinagem e Manutenção</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--120">
                    {/*Service section start*/}

                    <div className="">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <p class="justify-text">Atualmente os motores elétricos estão presentes em praticamente todas as instalações industriais, comerciais e até mesmo nas residências, realizando as mais variadas funções.</p>
                                    <p class="justify-text">Embora estas máquinas sejam conhecidas pela sua robustez, estas estão sempre sujeitas a condições de operação, levando ao desgaste de componentes e a avarias do foro mecânico e elétrico, quer no rotor, quer no estator.</p>
                                    <p class="justify-text">Assim sendo, torna-se importante fazer uma manutenção periódica (beneficiação), principalmente em casos de fim de vida útil do material isolante e/ou do material elétrico, ou até mesmo devido a uma avaria no equipamento, sendo necessário realizar uma rebobinagem do motor elétrico.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="service-section section-space--inner--60">
                        <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-12 order-2 order-lg-1">
                                {/* service gallery */}
                                <ServiceGallery galleryData={galleryData_bobinagem} />
                            </div>
                            <div className="col-lg-8 col-12 order-1 order-lg-2">
                            <div className="service-details">
                                <h2>Bobinagem</h2>
                                <div className="content section-space--top--30">
                                <div className="row">
                                    <div className="col-12">
                                        {/*<h2>Construction</h2>*/}
                                        <p class="justify-text">A Bobiset apresenta recursos humanos especializados e qualificados, com mais de 30 anos de experiência na arte de bobinar e no setor eletromecânico, nos mais variados tipos de equipamentos elétricos, desde os motores de corrente alternada, monofásicos e trifásicos, até aos geradores.</p>
                                    </div>
                                    <div id="manutencao" className="section-space--top--30 col-12">
                                        {/*<h2>Construction</h2>*/}
                                        <p class="justify-text">De forma a prestar um serviço de qualidade e de modo a garantir a durabilidade e eficiência original das máquinas, a Bobiset apenas trabalha com material isolante e elétrico certificado. Também aposta no investimento de novas tecnologias para testar o funcionamento elétrico e mecânico de todos os motores reparados nas nossas instalações.</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>

                        <div className="row section-space--top--60">
                            <div className="col-lg-8 col-12 order-2 order-lg-1">
                                <div className="service-details">
                                    <h2>Manutenção / Beneficiação</h2>
                                    <div className="content section-space--top--30">
                                    <div className="row">
                                        <div className="col-12">
                                            {/*<h2>Construction</h2>*/}
                                            <p class="justify-text">Devido ao desgaste do material, e ao fim de vida útil dos materiais nomeadamente do foro mecânico, a BobiSet realiza manutenções preventivas e periódicas, fortalecendo a durabilidade do equipamento e evitando intervenções de maior complexidade, as quais poderiam consequentemente ter um custo de serviço mais elevado e um maior impacto nas atividades fabris.</p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12 order-1 order-lg-2">
                                {/* service gallery */}
                                <ServiceGallery galleryData={galleryData_manutencao} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="service-details">
                                    <h4>SERVIÇOS</h4>
                                    <div className="content section-space--top--30">
                                    <div className="row">
                                        <div className="col-12">
                                            <ul className="ul-inside--styled">
                                                <li><strong>Bobinagem</strong> de motores elétricos, bombas submersíveis, bombas água, geradores;</li>
                                                <li><strong>Beneficiação</strong> de motores elétricos, bombas submersíveis, bombas água, geradores:
                                                    <ul className="ul-inside--styled">
                                                        <li>Desmontagem</li>
                                                        <li>Limpeza de todos os componentes</li>
                                                        <li>Lavagem de bobinagem com produto dielétrico próprio</li>
                                                        <li>Secagem em estufa ventilada</li>
                                                        <li>Envernizamento com verniz anti-flash</li>
                                                        <li>Montagem e pintura</li>
                                                    </ul>
                                                </li>
                                                <li>Substituição de <strong>rolamentos</strong> e <strong>retentores</strong></li>
                                                <li>Substituição de <strong>ventiladores, capots de ventilação, placas de ligação</strong></li>
                                                <li>Substituição de <strong>empanques mecânicos</strong></li>
                                                <li>Substituição de <strong>cabos elétricos</strong></li>
                                                <li><strong>Equilibragem estática e dinâmica</strong> de rotores</li>
                                                <li><strong>Ensaios elétricos</strong>, nomeadamente: resistência de isolamento e resistência ôhmica</li>
                                                <li><strong>Encasquilhamento de tampas</strong> de motores</li>
                                                <li><strong>Enchimento e retificação de veios</strong> de motores</li>
                                            </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        </div>
                    </div>
                    {/*Service section end*/}
                </div>


                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default Bobinagem;