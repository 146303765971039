import React, { Component } from 'react';

class FeatureIcon extends Component {
    render(){

        let data = [
            {featureIcon: "bobiset-icon.png", featureTitle: "Satisfação do cliente", featureDescription: "Acrescentar valor aos serviços prestados, suportadas por soluções sustentáveis."},
            {featureIcon: "bobiset-icon.png", featureTitle: "Respeito pelas pessoas", featureDescription: "Garantir a qualificação continua de todos os colaboradores, desenvolvendo competências para os diferentes desafios propostos, tanto a nível de qualidade como de segurança."},
            {featureIcon: "bobiset-icon.png", featureTitle: "Sustentabilidade", featureDescription: "Desenvolver de forma sustentável a atividade da empresa, através de uma conduta eticamente responsável, quer na escolha da matéria prima e produtos, quer na implementação de boas-práticas a todos os níveis na estrutura interna da empresa."},
            {featureIcon: "bobiset-icon.png", featureTitle: "Confiança", featureDescription: "Respeito pelos clientes, colegas e fornecedores, acreditando nas capacidades de trabalho de cada um defendendo os valores da empresa."},
            {featureIcon: "bobiset-icon.png", featureTitle: "Lealdade", featureDescription: "Rigor no trabalho, transparência nas relações, profissionalismo, de forma a salvaguardar o bom nome e boa imagem da empresa."},
            {featureIcon: "bobiset-icon.png", featureTitle: "Inovação", featureDescription: "Desenvolver novas tecnologias contribuindo para o desenvolvimento de vantagens competitivas face ao mercado."},
            {featureIcon: "bobiset-icon.png", featureTitle: "Ambiente", featureDescription: "Implementar boas práticas ambientais reduzindo os efeitos adversos resultantes da atividade e protegendo o ambiente"},
            {featureIcon: "bobiset-icon.png", featureTitle: "Segurança", featureDescription: "Assegurar as melhores condições de trabalho, valorizando a formação na área, e apostando em ações preventivas de modo a preservar o bem-estar dos colaboradores."},
            {featureIcon: "bobiset-icon.png", featureTitle: "Património", featureDescription: "Manter a qualidade de instalações e equipamentos, garantido a valorização de todos e dignificando o seu posto de trabalho."}
        ];

        let Datalist = data.map((val, i)=>{
            return(
                <div className="col-lg-4 col-md-6 col-12 section-space--bottom--30" key={i}>
                    <div className="feature">
                    <div className="icon">
                        <img src={`assets/img/bobiset/${val.featureIcon}`} className="img-fluid" alt="" />
                    </div>
                    <div className="content">
                        <h3>{val.featureTitle}</h3>
                        <p className="justify-text">{val.featureDescription}</p>
                    </div>
                    </div>
                </div>
            )
        });

        return(
            <div>
                {/*Feature section start*/}
                <div className={`feature-section section-space--inner--50 ${this.props.background}`}>
                    <div className="container">
                        <div className="col-lg-12">
                            <div className="feature-item-wrapper">
                                <div className="row">
                                    {Datalist}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Feature section end*/}
            </div>
        )
    }
}

export default FeatureIcon;