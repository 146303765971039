import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import FeatureIcon from '../components/FeatureIcon';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import ModalVideo from 'react-modal-video'
class About extends Component{

    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
      }
     
      openModal () {
        this.setState({isOpen: true})
      }

    render(){
        return(
            <div>

                {/* Navigation bar */}
                <NavBar/>
                
                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div id="quem-somos" className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Empresa</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li>Empresa</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--top--60">
                {/*About section start*/}
                <div className="about-section section-space--bottom--60">
                <div className="container">
                    <div className="row row-25 align-items-center">
                    <div className="col-lg-6 col-12 mb-30">
                        <div className="about-image-two">
                        <img src="assets/img/empresa/espaco.jpg" alt="Bobiset Setúbal" />
                        {/*
                            <span className="video-popup">
                                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='OrS-93U4AYQ' onClose={() => this.setState({isOpen: false})} />
                                <button onClick={this.openModal}>
                                    <i className="ion-ios-play-outline" />
                                </button>
                            </span>
                        */}
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 mb-30">
                        <div className="about-content-two">
                        <h3>Bem-vindo à Bobiset</h3>
                        <h1>QUEM SOMOS</h1>
                        <h4 class="justify-text">Somos uma empresa especializada em manutenções, bobinagem de equipamentos elétricos, nomeadamente motores, bombas de água, moto redutores, geradores, entre outros.</h4>
                        <p class="justify-text">Investimos em mão de obra especializada, com mais de 30 anos de experiência, assim como em equipamentos e materiais da mais alta qualidade.</p>
                        <p class="justify-text">Estarmos sempre disponíveis é o nosso compromisso, de modo a poder ajudar o nosso cliente, prestando com rigor um serviço de qualidade.</p>
                        <a href={`${process.env.PUBLIC_URL}/servicos/bobinagem-manutencao#bobinagem`} className="ht-btn--default ht-btn--default--dark-hover section-space--top--20">Os Nossos Serviços</a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*About section end*/}
                
                <div id="valores" className="text-center col-lg-12 col-12 mb-30 grey-bg">
                    <div className="about-content-two section-space--inner--top--60">
                        <h1>VALORES</h1>
                    </div>
                </div>
                {/* Feature Icon */}
                <FeatureIcon background = "grey-bg"/>

                {/*About section start*/}
                <div id="missao-compromissos" className="about-section">
                <div className="container">
                    <div className="about-wrapper row">
                    <div className="col-sm-6 col-12 order-1 order-lg-2">
                        <div className="about-image about-image-1">
                        <img src="assets/img/empresa/visao_2.svg" alt="" />
                        </div>
                    </div>
                    <div className="col-sm-6 col-12 order-2 order-lg-3">
                        <div className="about-image about-image-2">
                        <img src="assets/img/empresa/missao_2.svg" alt="Missão e valores" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 order-3 order-lg-1">
                        <div className="about-content about-content-two about-content-2 padding-left--none padding-right--70">
                        <h1>MISSÃO E COMPROMISSO</h1>
                        <p class="justify-text">Prestar um serviço de excelência, de modo a maximizar valor para o cliente, atuando de forma sustentável.</p>
                        <p class="justify-text">O nosso compromisso é estarmos disponíveis para o cliente, prestando um serviço rigoroso e de qualidade, de modo a sermos parceiros de negócio.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 order-4">
                        <div className="about-content about-content-two about-content-2">
                        <h1>VISÃO</h1>
                        <p class="justify-text">Ser uma empresa de referência, reconhecida como melhor opção por clientes, colaboradores e fornecedores pela qualidade dos nossos serviços.</p>
                        <a href={`${process.env.PUBLIC_URL}/contacto`} className="ht-btn--default ht-btn--default--dark-hover section-space--top--20">Entre em Contacto</a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*About section end*/}
                
                {/* Brand logo */}
                <BrandLogoSlider background = "" />

                </div>

                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default About;